import styled from 'styled-components'

const BannerVideo = styled.section`
  position: relative;
  #headerbg {
    background-image: url('https://www.incremental.com.au/video/header-bg-waves.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    float: right;
    margin-bottom: 3rem;
  }
  .home__banner__text {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    bottom: 0;
    height: 400px;
    left: 0;
    right: 0;
    top: 0;
  }
  /* background-position: center center;
  background-size: cover;
  color: #fff;
  display: block;
  &:before {
    content: '';
    display: block;
    padding-bottom: 33.333%;
    width: 100%;
    z-index: 2;
  }
  /* div {
    
    
  } */
  h1 {
    color: #fff;
    font-size: 3rem;
    font-weight: normal;
    margin: 0;
    padding: 0 0 0.8rem;
    text-align: center;
  }
  h2 {
    color: #fff;
    font-size: 2rem;
    font-weight: normal;
    margin: 0;
    padding: 0 0 0.8rem;
    text-align: center;
  }
  p {
    color: #fff;
    font-size: 1rem;
    font-weight: normal;
    margin: 0;
    padding: 0;
    text-align: center;
    a {
      color: #f4bd19;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
`

export default BannerVideo
